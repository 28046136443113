<template>
    <div>
        <div class="block">
            <div v-for="(item, n) in cmsLeft" :key="n">
                <div v-if="item.Type === 'breadcrumb'">
                    <breadcrumb :data="item" />
                </div>
                <div v-else-if="item.Type === 'contactus'">
                    <contact-us :data="item.Data" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Breadcrumb: () => import('@/components/breadcrumb/breadcrumb'),
        ContactUs: () => import('@/components/contactus')
    },
    data () {
        return {
            cmsLeft: []
        }
    },
    methods: {
        async init () {
            var response = await this.$baseApi.get(this.$route.fullPath)
            this.cmsLeft = response.Left
        }
    },
    mounted () {
        this.init()
    }
}
</script>
